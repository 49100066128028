<template>
  <form class="webhook-add-edit" @submit.prevent>
    <DashboardHeader :title="headerText" />
    <ChecLoading v-if="isLoading" without-background />
    <div v-else class="webhook-add-edit__content space-y-4 xl:space-y-0">
      <div class="webhook-add-edit__main space-y-4">
        <ChecCard tailwind="p-4">
          <ChecLoading v-if="isLoading" />
          <ChecHeader
            :title="$t('general.details')"
            variant="card"
            header-tag="h3"
          />
          <ChecDropdown
            v-if="eventList.length"
            v-model="events"
            name="subscribed_events"
            :label="$t('webhooks.events')"
            :options="eventList"
            :variant="isSaving ? 'disabled' : ''"
            multiselect
          />
          <TextField
            v-model="url"
            name="url"
            :label="$t('general.url')"
            class="webhook-add-edit__url"
            placeholder="https://"
            :variant="inputState"
          />
          <span class="webhook-add-edit__validation-error">
            {{ validationError }}
          </span>

          <ChecSwitch
            v-model="active"
            name="active"
            class="webhook-add-edit__active"
            :disabled="isSaving"
          >
            {{ switchLabel }}
          </ChecSwitch>

          <TextField
            v-model="signingKey"
            :label="$t('webhooks.signingKey')"
            readonly
            :variant="isSaving ? 'disabled' : ''"
            class="webhook-add-edit__signing-key"
          >
            <ChecButton
              v-tooltip="$t('webhooks.generateKey')"
              color="primary"
              variant="round"
              :disabled="isSaving"
              :title="$t('webhooks.generateKey')"
              icon="refresh"
              outline
              @click="regenerateKey"
            />
            <CopyButton
              v-if="signingKey"
              class="webhook-add-edit__copy-button"
              :disabled="isSaving"
              :copy-content="signingKey"
              color="primary"
            />
          </TextField>
        </ChecCard>
        <ChecCard v-if="webhook" tailwind="p-4">
          <WebhookDeliveryHistory />
        </ChecCard>
      </div>
      <div class="webhook-add-edit__aside space-y-4">
        <ActionsCard
          :allow-delete="!isAdding"
          :saving="isSaving"
          :webhook="webhook"
          class="webhook-add-edit__actions-card"
          @save-webhook="handleSaveWebhook"
          @delete-webhook="handleDeleteWebhook"
        />
        <InfoCard
          v-if="webhook"
          :webhook="webhook"
        />
      </div>
      <ActionsCard
        :allow-delete="!isAdding"
        :saving="isSaving"
        :webhook="webhook"
        class="webhook-add-edit__actions-card--mobile"
        @save-webhook="handleSaveWebhook"
        @delete-webhook="handleDeleteWebhook"
      />
    </div>
  </form>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecDropdown,
  ChecHeader,
  ChecLoading,
  ChecSwitch,
  TextField,
} from '@chec/ui-library';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { makeApiRequest } from '@/lib/api';
import actions from '@/store/actions';
import mutations from '@/store/mutations';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import DashboardHeader from '@/components/DashboardHeader.vue';
import CopyButton from '@/components/CopyButton.vue';
import ActionsCard from '../components/ActionsCard.vue';
import InfoCard from '../components/InfoCard.vue';
import WebhookDeliveryHistory from '../components/WebhookDeliveryHistory.vue';

const {
  APPEND_WEBHOOK,
  UPDATE_WEBHOOK,
  FETCH_WEBHOOK_SIGNING_KEY,
  REMOVE_WEBHOOK,
} = actions;

export default {
  name: 'WebhookAddEdit',
  components: {
    ChecButton,
    ChecCard,
    ChecDropdown,
    ChecHeader,
    ChecLoading,
    ChecSwitch,
    CopyButton,
    TextField,
    DashboardHeader,
    ActionsCard,
    InfoCard,
    WebhookDeliveryHistory,
  },
  mixins: [addNotification, confirm],
  data() {
    return {
      events: [],
      url: '',
      active: true,
      isSaving: false,
      validationError: '',
      regeneratingKey: false,
    };
  },
  computed: {
    ...mapGetters('webhooks', ['getWebhook']),
    ...mapState('webhooks', ['webhooks', 'types', 'signingKey', 'isLoading']),
    /**
     * Are we adding?
     *
     * @returns {boolean}
     */
    isAdding() {
      return this.$route.name === 'settings.webhooks.add';
    },
    /**
     * Outputs 'disabled' or 'error' if saving for input prop
     *
     * @returns {String|null}
     */
    inputState() {
      if (this.isSaving) {
        return 'disabled';
      }
      if (this.validationError) {
        return 'error';
      }
      return null;
    },
    /**
     * Returns an "options" array for the dropdown multiselect from available webhook event types
     *
     * @returns {Array}
     */
    eventList() {
      const types = this.types || [];
      return types.map((type) => ({ value: type, label: type }));
    },
    /**
     * Returns the webhook to be edited from state, if editing
     *
     * @returns {object|null}
     */
    webhook() {
      return this.$route.params && this.getWebhook(this.$route.params.id);
    },
    /**
     * Returns the header title
     *
     * @returns {string}
     */
    headerText() {
      return this.webhook ? this.$t('webhooks.edit') : this.$t('webhooks.add');
    },
    /**
     * Returns the label for the active switch
     *
     * @returns {string}
     */
    switchLabel() {
      return this.active ? this.$t('general.active') : this.$t('general.inactive');
    },
  },
  watch: {
    webhook(val) {
      this.updateWebhookState(val);
    },
  },
  mounted() {
    // Ensure state is loaded, otherwise update global loading state
    if (this.webhooks === false) {
      this[mutations.SET_LOADING]({ context: 'webhooks' });
    }
    if (!this.types.length) {
      this[mutations.SET_LOADING]({ context: 'webhookTypes' });
    }
    this.updateWebhookState(this.webhook);
  },
  methods: {
    ...mapMutations([mutations.SET_LOADING]),
    ...mapActions('webhooks', { fetchSigningKey: FETCH_WEBHOOK_SIGNING_KEY }),
    updateWebhookState(webhook) {
      if (!webhook) {
        this.events = [];
        this.url = '';
        this.active = true;
        return;
      }

      const { subscribed_events: events, url, active } = webhook;

      this.events = events || [];
      this.url = url;
      this.active = active;
    },
    /**
     * Save the webhook
     */
    handleSaveWebhook() {
      const httpMethod = this.isAdding ? 'post' : 'put';
      const uri = this.isAdding ? '/v1/webhooks' : `/v1/webhooks/${this.webhook.id}`;

      if (!this.url) {
        this.validationError = this.$t('validation.enterUrl');
        return;
      }

      this.isSaving = true;

      makeApiRequest(httpMethod, uri, {
        url: this.url,
        subscribed_events: this.events,
        active: this.active !== null ? this.active : true,
      })
        .then(({ data }) => {
          this.addNotification(
            this.isAdding ? this.$t('webhooks.added') : this.$t('webhooks.saved'),
          );
          if (this.isAdding) {
            this.$router.push({ name: 'settings.webhooks.edit', params: { id: data.data.id } });
            this.$store.dispatch(`webhooks/${APPEND_WEBHOOK}`, data.data);
          } else {
            this.$store.dispatch(`webhooks/${UPDATE_WEBHOOK}`, data.data);
          }
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            // We only really validate the URL field
            this.validationError = this.$t('validation.validUrl');
            return;
          }

          // Errors other than validation should be handled with a notification
          this.addNotification(
            this.$t('webhooks.saveFailed'),
            'error',
          );
        })
        .finally(() => {
          this.validationError = '';
          this.isSaving = false;
        });
    },
    /**
     * Delete a webhook
     */
    async handleDeleteWebhook() {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('webhooks.confirmDelete'),
      )) {
        return;
      }

      this.$store
        .dispatch(`webhooks/${REMOVE_WEBHOOK}`, this.webhook.id)
        .then(() => {
          this.$router.push({ name: 'settings.webhooks.home' });
          this.addNotification(this.$t('webhooks.deleted'));
        })
        .catch(() => {
          this.addNotification(this.$t('webhooks.deleteFailed'), 'error');
        });
    },
    async regenerateKey() {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('webhooks.confirmRegenerateKey'),
      )) {
        return;
      }

      this.regeneratingKey = true;

      this.fetchSigningKey(true)
        .catch(() => {
          this.addNotification(
            this.$t('regenerateKeyFailed'),
            'error',
          );
        })
        .finally(() => {
          this.regeneratingKey = false;
        });
    },
  },
};
</script>

<style lang="scss">
.webhook-add-edit {
  &__aside {
    @screen xl {
      @apply ml-8 w-1/3 max-w-sm flex-shrink-0;
    }
  }

  @screen xl {
    &__content {
      @apply flex flex-row;
    }

    &__main {
      @apply flex-grow;
    }
  }

  &__actions-card {
    @apply hidden;

    @screen xl {
      @apply block;
    }

    &--mobile {
      @apply mt-4;

      @screen xl {
        @apply hidden;
      }
    }
  }

  .card {
    @apply mb-8;

    .chec-header {
      @apply mb-4;
    }
  }

  .chec-table {
    @apply mb-8; // Specific margin-bottom for table similar to card bottom margin on line 383
  }

  .webhooks__table {
    @apply w-full;
  }

  &__url {
    @apply mt-2;
  }

  &__active {
    @apply my-4 caps-xxs;
  }

  &__toolbar {
    @apply w-full flex justify-end;
  }

  &__validation-error {
    @apply text-sm text-red-600 mt-1;
  }

  &__cancel.button {
    @apply p-0;
  }

  &__copy-button {
    @apply ml-2;
  }

  &__copy-button-icon {
    height: 13px;
  }
}
</style>
