<template>
  <router-view />
</template>

<script>
import actions from '@/store/actions';

const {
  FETCH_WEBHOOK_EVENTS,
  FETCH_WEBHOOK_SIGNING_KEY,
  FETCH_WEBHOOKS,
  FETCH_WEBHOOKS_HISTORY,
} = actions;

export default {
  name: 'WebhooksIndex',
  created() {
    // Pre-fetch webhook data that will be commonly used in all screens
    this.$store.dispatch(`webhooks/${FETCH_WEBHOOKS}`);
    this.$store.dispatch(`webhooks/${FETCH_WEBHOOKS_HISTORY}`, 'all');
    this.$store.dispatch(`webhooks/${FETCH_WEBHOOK_SIGNING_KEY}`);
    this.$store.dispatch(`webhooks/${FETCH_WEBHOOK_EVENTS}`);
  },
};
</script>
