<template>
  <div class="webhooks__delivery">
    <DashboardHeader
      :title="$t('webhooks.deliveryHistory')"
      :variant="stateKey === 'all' ? 'section' : 'card'"
      header-tag="h3"
    >
      <ChecButton
        v-if="stateKey !== 'all'"
        :disabled="sendingTestRequest"
        variant="round"
        color="primary"
        @click="handleSendTestRequest(stateKey)"
      >
        {{ sendingTestRequest
          ? $t('webhooks.sendingTestRequest')
          : $t('webhooks.sendTestRequest')
        }}
      </ChecButton>
    </DashboardHeader>

    <table
      class="webhooks__table"
      :class="{
        'webhooks__table--empty': !loading && history.length === 0
      }"
    >
      <thead>
        <tr>
          <th v-if="stateKey === 'all'" class="webhooks__id">
            {{ $t('webhooks.webhook') }}
          </th>
          <th>{{ $t('webhooks.event') }}</th>
          <th>{{ $t('general.timestamp') }}</th>
          <th>{{ $t('general.url') }}</th>
          <th class="text-right">
            {{ $t('general.status') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td :colspan="numColumns" class="webhooks__table--loading">
            <ChecLoading v-if="!appIsLoading" />
          </td>
        </tr>
        <tr v-else-if="history.length === 0">
          <td :colspan="numColumns">
            {{ $t('general.empty') }}
          </td>
        </tr>
        <tr
          v-for="(message, i) in history"
          v-else
          :key="i"
          class="webhooks__table--row"
          @click="handleViewResponse(message)"
        >
          <td v-if="stateKey === 'all'" class="webhooks__delivery-event">
            <div v-if="message.webhook_id" class="webhook-table__webhook-id-container">
              <CopyInline
                class="webhook-table__inline"
                :copy-content="message.webhook_id || ''"
              >
                <div
                  class="webhook-table__webhook-id"
                >
                  {{ message.webhook_id }}
                </div>
              </CopyInline>
            </div>
          </td>
          <td>
            <code>{{ message.event }}</code>
          </td>
          <td class="webhooks__delivery-timestamp">
            <Timestamp
              :timestamp="parseInt(message.created, 10)"
              from-now
            />
          </td>
          <td class="webhooks__delivery-url">
            {{ message.url }}
          </td>
          <td
            class="webhooks__delivery-status"
            :class="{
              'webhooks__delivery-status--success':
                statusColor(message.response_code) !== 'red',
              'webhooks__delivery-status--failure':
                statusColor(message.response_code) === 'red',
            }"
          >
            <ChecDataPill :color="statusColor(message.response_code)">
              {{ message.response_code }}
            </ChecDataPill>
          </td>
        </tr>
      </tbody>
    </table>
    <WebhookLogDetails
      v-if="loadedMessage"
      :webhook="loadedMessage"
      show-id
      @close="loadedMessage = null"
    />
  </div>
</template>

<script>
import {
  ChecButton,
  ChecDataPill,
  ChecLoading,
} from '@chec/ui-library';
import { mapGetters } from 'vuex';
import { makeApiRequest } from '@/lib/api';
import actions from '@/store/actions';
import addNotification from '@/mixins/addNotification';
import DashboardHeader from '@/components/DashboardHeader.vue';
import Timestamp from '@/components/Timestamp.vue';
import WebhookLogDetails from '@/components/WebhookLogDetails.vue';
import CopyInline from '@/components/CopyInline.vue';

const {
  APPEND_WEBHOOK_HISTORY,
  FETCH_WEBHOOKS_HISTORY,
} = actions;

export default {
  name: 'WebhookDeliveryHistory',
  components: {
    Timestamp,
    ChecButton,
    ChecDataPill,
    ChecLoading,
    DashboardHeader,
    WebhookLogDetails,
    CopyInline,
  },
  mixins: [addNotification],
  data() {
    return {
      sendingTestRequest: false,
      loadedMessage: null,
    };
  },
  computed: {
    ...mapGetters('webhooks', ['isWebhookHistoryLoaded', 'webhookHistory']),
    ...mapGetters({ appIsLoading: 'isLoading' }),
    stateKey() {
      return this.$route.params.id || 'all';
    },
    history() {
      return this.webhookHistory(this.$route.params.id);
    },
    loading() {
      return !this.isWebhookHistoryLoaded(
        this.$route.params.id,
      );
    },
    /**
     * When viewing a specific webhook we do not render the webhook ID column
     *
     * @returns {number}
     */
    numColumns() {
      return this.stateKey === 'all' ? 5 : 4;
    },
  },
  mounted() {
    const webhookId = this.$route.params.id || null;
    // Fetch a specific records history (more records are provided than the global list)
    if (webhookId && this.loading) {
      this.$store.dispatch(`webhooks/${FETCH_WEBHOOKS_HISTORY}`, webhookId);
    }
  },
  methods: {
    handleViewResponse(message) {
      this.loadedMessage = message;
    },
    /**
     * Determine the data pill color from the status code
     */
    statusColor(statusCode) {
      if (statusCode >= 200 && statusCode < 300) {
        return 'green';
      }
      return 'red';
    },
    /**
     * Triggers the API to send a test request to the provided webhook ID
     *
     * @param {string} webhookId
     */
    handleSendTestRequest(webhookId) {
      this.sendingTestRequest = true;

      return makeApiRequest('post', `/v1/webhooks/${webhookId}/test`)
        .then(({ data }) => {
          this.$store.dispatch(`webhooks/${APPEND_WEBHOOK_HISTORY}`, {
            newRecord: data.data,
            webhookId,
          });

          if (data.data.response_code >= 300) {
            // If the API request was successful but the client's callback URL returned an invalid
            // status code, show an error message anyway
            this.addNotification(
              this.$t('webhooks.testRequestCallbackFailed'),
              'error',
            );
            return;
          }

          this.addNotification(this.$t('webhooks.testRequestSuccess'));
        })
        .catch((error) => {
          this.addNotification(this.$t('webhooks.testRequestFailed'), 'error');
          throw error;
        })
        .finally(() => {
          this.sendingTestRequest = false;
        });
    },
  },
};
</script>

<style lang="scss">
.webhooks {
  &__delivery-status {
    @apply text-right;

    &--success {
      @apply text-green-600;
    }

    &--failure {
      @apply text-red-600;
    }
  }

  &__delivery-timestamp {
    @apply caps-xs;
  }

  &__delivery-url {
    @apply break-all;
  }

  &__table {
    @apply mt-4 w-full;

    &--empty {
      td {
        @apply text-gray-400;
      }
    }

    &--loading {
      @apply relative p-16;
    }

    &--row {
      @apply cursor-pointer;
    }

    code {
      @apply py-1 px-2 bg-gray-200 rounded-sm break-words;
      @apply text-xs font-mono tracking-wide text-gray-600;
    }
  }
}

.webhooks {
  &__id {
    width: 195px; // Custom value to match standard width of webhook ID
  }
}
</style>
