<template>
  <ChecTable
    class="webhooks__info-card"
  >
    <tbody>
      <tr>
        <td class="webhooks__info-card-header">
          {{ $t('general.id') }}
        </td>
        <td>
          <code>{{ webhook.id }}</code>
        </td>
      </tr>
      <tr>
        <td>
          {{ $t('webhooks.created') }}
        </td>
        <td>
          <Timestamp :timestamp="webhook.created" />
        </td>
      </tr>
      <tr>
        <td class="webhooks__info-card-header--events">
          {{ $t('webhooks.events') }}
        </td>
        <td>
          <div class="webhooks__info-card-events">
            <code
              v-for="event in webhook.subscribed_events"
              :key="event"
            >
              {{ event }}
            </code>
          </div>
        </td>
      </tr>
    </tbody>
  </ChecTable>
</template>

<script>
import {
  ChecTable,
} from '@chec/ui-library';
import Timestamp from '@/components/Timestamp.vue';

export default {
  name: 'InfoCard',
  components: {
    ChecTable,
    Timestamp,
  },
  props: {
    webhook: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.webhooks {
  &__info-card {
    .chec-table__table {
      @apply w-full;

      td:first-of-type {
        @apply caps-xxs;
      }

      td:last-of-type {
        @apply text-right;
      }
    }

    code {
      @apply py-1 px-2 bg-gray-200 rounded-sm break-words
        text-xs font-mono tracking-wide text-gray-600;
    }
  }

  &__info-card-header {
    @apply w-1/3;

    &--events {
      @apply flex;
    }
  }

  &__info-card-events {
    code {
      @apply inline-block; // Override code to display block

      &:not(:last-child) {
        @apply ml-2 mb-2;
      }

      @screen lg {
        @apply mr-0;
      }
    }
  }
}
</style>
