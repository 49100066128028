<template>
  <div class="webhooks">
    <WebhooksTable />
    <WebhookDeliveryHistory v-if="webhooks.length" class="mt-20" />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import WebhooksTable from '../components/WebhooksTable.vue';
import WebhookDeliveryHistory from '../components/WebhookDeliveryHistory.vue';

export default {
  name: 'WebhooksHome',
  components: {
    WebhookDeliveryHistory,
    WebhooksTable,
  },
  computed: {
    ...mapState('webhooks', ['webhooks']),
  },
};
</script>

<style lang="scss">
.webhooks {
  @apply text-gray-600;

  h2 {
    @apply font-bold text-2xl;
  }

  a {
    @apply text-gray-500;

    &:hover {
      @apply text-gray-600;
    }
  }

  code {
    @apply py-1 px-2 bg-gray-200 rounded-sm break-words;
    @apply text-xs font-mono tracking-wide text-gray-600;
  }

  &__table {
    @apply mt-4 w-full;

    &--empty {
      td {
        @apply text-gray-400;
      }
    }

    &--loading {
      @apply relative p-16;
    }

    code {
      @apply inline-block my-1 mr-2;
    }
  }

  h2 {
    @apply font-bold text-2xl;
  }

  code {
    @apply py-1 px-2 rounded-sm;
    @apply text-xs font-mono tracking-wide text-gray-600;
  }

  &__toolbar {
    @apply m-4 flex items-center;

    h2 {
      @apply flex-grow;
    }
  }
}
</style>
