<template>
  <div class="webhook-table">
    <DashboardHeader :title="$t('webhooks.webhooks')">
      <ChecButton
        color="primary"
        variant="round"
        class="webhooks__add-button"
        icon="plus"
        @click="handleAddNew"
      >
        <template #icon>
          <ChecIcon icon="plus" />
        </template>
        {{ $t('general.add') }}
      </ChecButton>
    </DashboardHeader>

    <IntroSplash
      v-if="showIntroSplash"
      :title="$t('webhooks.introSplash.title')"
      :description="$t('webhooks.introSplash.description')"
      button
      @click="handleAddNew"
    >
      <i18n path="webhooks.introSplash.description" tag="span">
        <template #more>
          <a href="http://support.commercejs.com/en/articles/5544479-configuring-webhooks" target="_blank">
            {{ $t('webhooks.introSplash.more') }}
          </a>
        </template>
      </i18n>
    </IntroSplash>

    <DashboardTable
      v-else
      class="webhooks__table"
      :align-right-after="3"
      :columns="[
        { title: $t('general.id'), class: 'webhook-table__id' },
        { title: $t('webhooks.events'), class: 'webhook-table__event' },
        { title: $t('general.url'), class: 'webhook-table__url' },
        { title: $t('general.options') },
      ]"
      state-key="webhooks"
    >
      <TableRow
        v-for="webhook in getWebhooks"
        :id="webhook.id"
        :key="webhook.id"
        class="webhook"
        route-name="settings.webhooks.edit"
      >
        <td>
          <div v-if="webhook.id" class="webhook-table__webhook-id-container">
            <CopyInline
              class="webhook-table__inline"
              :copy-content="webhook.id || ''"
            >
              <div
                class="webhook-table__webhook-id"
              >
                {{ webhook.id }}
              </div>
            </CopyInline>
          </div>
        </td>
        <td>
          <code
            v-for="event in eventsList(webhook)"
            :key="event"
            :class="[
              isMoreCountElement && webhook.subscribed_events.length > 3 ? moreCountClass : ''
            ]"
            class="webhook-table__event"
          >
            {{ event }}
          </code>
        </td>
        <td class="webhook-table__url">
          {{ webhook.url }}
        </td>
        <td class="dashboard-table__actions-button">
          <ChecOptionsMenu menu-placement="bottom-end">
            <ChecOption @option-selected="handleEdit(webhook.id)">
              {{ $t('general.edit') }}
            </ChecOption>
            <ChecOption
              class="text-red-600"
              @option-selected="handleDelete(webhook.id)"
            >
              {{ $t('general.delete') }}
            </ChecOption>
          </ChecOptionsMenu>
        </td>
      </TableRow>
    </DashboardTable>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  ChecButton,
  ChecOption,
  ChecIcon,
  ChecOptionsMenu,
} from '@chec/ui-library';
import DashboardHeader from '@/components/DashboardHeader.vue';
import IntroSplash from '@/components/IntroSplash.vue';
import actions from '@/store/actions';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import DashboardTable from '@/components/DashboardTable.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';
import CopyInline from '@/components/CopyInline.vue';

const {
  REMOVE_WEBHOOK,
} = actions;

export default {
  name: 'WebhooksTable',
  components: {
    TableRow,
    DashboardTable,
    ChecButton,
    ChecOption,
    ChecIcon,
    ChecOptionsMenu,
    DashboardHeader,
    IntroSplash,
    CopyInline,
  },
  mixins: [addNotification, confirm],
  data() {
    return {
      isMoreCountElement: false,
      moreCountClass: 'more-count',
    };
  },
  computed: {
    ...mapState('webhooks', ['webhooks', 'isLoading']),
    getWebhooks() {
      return this.webhooks || [];
    },
    /**
     * Show intro card section when there are no webhooks and when
     * page is not loading
     *
     * @returns {boolean}
     */
    showIntroSplash() {
      return this.webhooks.length === 0 && !this.isLoading;
    },
  },
  methods: {
    /**
     * Returns the webhooks subscribed events array according to count
     */
    eventsList(webhook) {
      const webhookEvents = [...webhook.subscribed_events];
      if (webhookEvents.length <= 3) {
        return webhookEvents;
      }
      if (webhookEvents.length > 3) {
        const moreCount = this.$t('general.andMore', { n: webhookEvents.length - 3 });
        this.isMoreCountElement = true;
        return (webhookEvents.splice(0, 3)).concat(moreCount);
      }
      return null;
    },
    /**
     * Delete a webhook
     *
     * @param {string} webhookId
     */
    async handleDelete(webhookId) {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('webhooks.confirmDelete'),
      )) {
        return;
      }

      this.$store
        .dispatch(`webhooks/${REMOVE_WEBHOOK}`, webhookId)
        .then(() => {
          this.addNotification(this.$t('webhooks.deleted'));
        })
        .catch(() => {
          this.addNotification(this.$t('webhooks.deleteFailed'), 'error');
        });
    },
    /**
     * Click handler for "add webhook" button, opens a modal
     */
    handleAddNew() {
      this.$router.push({ name: 'settings.webhooks.add' });
    },
    /**
     * Click handler for "view webhook details"
     *
     * @param {string} id
     */
    handleView(id) {
      this.$router.push({
        name: 'settings.webhooks.view',
        params: { id },
      });
    },
    /**
     * Click handler for the "edit webhook" option. Opens a modal.
     */
    handleEdit(id) {
      this.$router.push({
        name: 'settings.webhooks.edit',
        params: { id },
      });
    },
  },
};
</script>

<style lang="scss">
.webhook-table {
  &__header-id {
    width: 195px; // Custom value to match standard width of webhook ID
  }

  &__header-event {
    @apply w-1/2;
  }

  &__header-url {
    @apply w-1/2;
  }

  &__event {
    &:not(:last-child) {
      @apply mr-2;
    }
  }

  // Override code style for 'more-element'
  .more-count:last-child {
    @apply border-none bg-transparent text-gray-600 px-0 font-lato;
  }

  &__url {
    @apply break-all;
  }

  &__webhook-id-container {
    @apply inline-block items-center border border-gray-300 p-2 bg-gray-100 rounded;

    min-width: 10rem;
  }

  &__webhook-id {
    @apply font-mono text-xs select-all break-all;
  }
}
</style>
