<template>
  <ChecButton
    v-if="hasNavigatorClipboard"
    :color="color"
    :disabled="disabled"
    variant="round"
    @click="handleCopy"
  >
    {{ copyText }}
  </ChecButton>
</template>

<script>
import { ChecButton } from '@chec/ui-library';

export default {
  name: 'CopyButton',
  components: {
    ChecButton,
  },
  props: {
    copyContent: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
      default: 'secondary',
    },
    disabled: Boolean,
  },
  data() {
    return {
      copied: false,
      timers: [],
    };
  },
  computed: {
    copyText() {
      return this.copied ? 'Copied!' : 'Copy';
    },
  },
  methods: {
    /**
     * @returns {boolean}
     */
    hasNavigatorClipboard() {
      return (
        typeof window.navigator !== 'undefined'
        && typeof window.navigator.clipboard !== 'undefined'
      );
    },
    handleCopy() {
      window.navigator.clipboard.writeText(this.copyContent).then(() => {
        this.copied = true;

        // Cancel old timers
        this.timers.forEach((timer) => window.clearTimeout(timer));
        this.timers = [];

        // Push new timer
        this.timers.push(
          window.setTimeout(() => {
            this.copied = false;
          }, 3000),
        );
      });
    },
  },
};
</script>
