var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"webhooks__delivery"},[_c('DashboardHeader',{attrs:{"title":_vm.$t('webhooks.deliveryHistory'),"variant":_vm.stateKey === 'all' ? 'section' : 'card',"header-tag":"h3"}},[(_vm.stateKey !== 'all')?_c('ChecButton',{attrs:{"disabled":_vm.sendingTestRequest,"variant":"round","color":"primary"},on:{"click":function($event){return _vm.handleSendTestRequest(_vm.stateKey)}}},[_vm._v(" "+_vm._s(_vm.sendingTestRequest ? _vm.$t('webhooks.sendingTestRequest') : _vm.$t('webhooks.sendTestRequest'))+" ")]):_vm._e()],1),_c('table',{staticClass:"webhooks__table",class:{
      'webhooks__table--empty': !_vm.loading && _vm.history.length === 0
    }},[_c('thead',[_c('tr',[(_vm.stateKey === 'all')?_c('th',{staticClass:"webhooks__id"},[_vm._v(" "+_vm._s(_vm.$t('webhooks.webhook'))+" ")]):_vm._e(),_c('th',[_vm._v(_vm._s(_vm.$t('webhooks.event')))]),_c('th',[_vm._v(_vm._s(_vm.$t('general.timestamp')))]),_c('th',[_vm._v(_vm._s(_vm.$t('general.url')))]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('general.status'))+" ")])])]),_c('tbody',[(_vm.loading)?_c('tr',[_c('td',{staticClass:"webhooks__table--loading",attrs:{"colspan":_vm.numColumns}},[(!_vm.appIsLoading)?_c('ChecLoading'):_vm._e()],1)]):(_vm.history.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":_vm.numColumns}},[_vm._v(" "+_vm._s(_vm.$t('general.empty'))+" ")])]):_vm._l((_vm.history),function(message,i){return _c('tr',{key:i,staticClass:"webhooks__table--row",on:{"click":function($event){return _vm.handleViewResponse(message)}}},[(_vm.stateKey === 'all')?_c('td',{staticClass:"webhooks__delivery-event"},[(message.webhook_id)?_c('div',{staticClass:"webhook-table__webhook-id-container"},[_c('CopyInline',{staticClass:"webhook-table__inline",attrs:{"copy-content":message.webhook_id || ''}},[_c('div',{staticClass:"webhook-table__webhook-id"},[_vm._v(" "+_vm._s(message.webhook_id)+" ")])])],1):_vm._e()]):_vm._e(),_c('td',[_c('code',[_vm._v(_vm._s(message.event))])]),_c('td',{staticClass:"webhooks__delivery-timestamp"},[_c('Timestamp',{attrs:{"timestamp":parseInt(message.created, 10),"from-now":""}})],1),_c('td',{staticClass:"webhooks__delivery-url"},[_vm._v(" "+_vm._s(message.url)+" ")]),_c('td',{staticClass:"webhooks__delivery-status",class:{
            'webhooks__delivery-status--success':
              _vm.statusColor(message.response_code) !== 'red',
            'webhooks__delivery-status--failure':
              _vm.statusColor(message.response_code) === 'red',
          }},[_c('ChecDataPill',{attrs:{"color":_vm.statusColor(message.response_code)}},[_vm._v(" "+_vm._s(message.response_code)+" ")])],1)])})],2)]),(_vm.loadedMessage)?_c('WebhookLogDetails',{attrs:{"webhook":_vm.loadedMessage,"show-id":""},on:{"close":function($event){_vm.loadedMessage = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }